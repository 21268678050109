import React from 'react'
import PortableText from "../portableText"
import { StaticQuery, graphql, Link } from 'gatsby'

export const FooterDefault = ({ columns = [] }) => {
    return (
        <>
            <StaticQuery
                query={footerQuery}
                render={({ site = {} }) => {

                    const { _rawFooter = [] } = site
                    const _columns = (columns.length !== 0 ? columns : _rawFooter) || []


                    return (<>
                        {_columns.length > 0 && <footer className="bg-gray-200">
                            <div className="flex flex-row justify-around max-w-6xl m-auto py-16">
                                {
                                    _columns.map(({ title, body }) => {
                                        return (
                                            <div key={title} className="flex-1 px-4">
                                                {title && <h3 className="font-roboto-slab text-lg font-semibold text-gray-700">{title}</h3>}
                                                {body && <PortableText blocks={body} />}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </footer>
                        }
                    </>)
                }} />
            <div className="bg-transparent max-w-6xl m-auto py-8 px-4 md:px-0 text-gray-800">
                © Angsthelden | <a className="text-orange-500 underline" href="https://www.vridz.nl/">Onderdeel van VRIDZ</a> | Ontwerp door Weijland.IT  |  Alle rechten voorbehouden  |  Powered by Gatsby.JS  |  <Link className="text-orange-500 underline" to="/algemene-voorwaarden">Algemene voorwaarden</Link>  |  <Link className="text-orange-500 underline" to="/privacy-verklaring">Privacyverklaring</Link>
            </div>
            {/* <script type="text/javascript">
                {`
                var _linkedin_partner_id = "2408508";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                `}
            </script>
            <script type="text/javascript">
                {`
                  (function(){var s = document.getElementsByTagName("script")[0];
                  var b = document.createElement("script");
                  b.type = "text/javascript";b.async = true;
                  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                  s.parentNode.insertBefore(b, s);})();
                `}
            </script>
            <noscript>
                {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2408508&fmt=gif" />`}
            </noscript> */}
        </>
    )
}

const footerQuery = graphql`
  query DefaultFooterQuery {
    site: sanitySiteSettings(_id: {eq: "siteSettings"}) {
      _rawFooter
    }
  }
`
