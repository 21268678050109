import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState, useEffect } from 'react'
import Icon from './icon'
import { cn } from '../lib/helpers'
import BackgroundImage from 'gatsby-background-image'


const Header = ({ onHideNav, onShowNav, showNav, showBanner = true, siteTitle, subTitle, cta, hideOverlay = false, backgroundImage = {} }) => {

	const [offset, setOffset] = useState(0)
	const [isOpened, setIsOpened] = useState(false)

	const onOpen = () => {
		setIsOpened(true)
	}

	const onClose = () => {
		setIsOpened(false)
	}

	const onToggle = () => {
		setIsOpened(!isOpened)
	}

	useEffect(() => {
		const listener = () => {
			setOffset(window.pageYOffset)
		}

		window.addEventListener('scroll', listener, { passive: true })

		return () => window.removeEventListener('scroll', listener)
	}, [])

	// background: linear-gradient(rgba(0, 0, 40, 0.75), rgba(0, 0, 40, 0.75)), url(/images/header-background2.jpg); background-position: center; background-attachment: fixed; 

	let Cta = null

	if(cta && cta.label && cta.url) {
		if(cta.url.includes("https://")) {
			Cta = <a href={cta.url || "/"} className="mt-16 px-8 py-3 text-lg rounded-lg bg-orange-400 uppercase tracking-widest">{cta.label}</a>
		} else {
			Cta = <Link to={cta.url || "/"} className="mt-16 px-8 py-3 text-lg rounded-lg bg-orange-400 uppercase tracking-widest">{cta.label}</Link>
		}
	}

	return (
		<StaticQuery
			query={headerQuery}
			render={({ site = {} }) => {

				const { menu = [], logoImage = {} } = site
				const { asset = {} } = logoImage
				const { fluid } = asset

				let backgroundImageStack = [backgroundImage]

				if(!hideOverlay) {
					backgroundImageStack.push("linear-gradient(rgba(0, 0, 40, 0.3), rgba(0, 0, 40, 0.3))")
				}

				return (<>
					<MobileNavBar menu={menu} logo={fluid} isOpened={isOpened} onOpen={onOpen} onClose={onClose} onToggle={onToggle} />
					<header className="md:hidden fixed left-4 top-4 right-4 flex flex-row justify-between z-10">
						<div>
						</div>
						<div>
							<button className="p-4 bg-white rounded-full shadow-xl text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden" onClick={() => onToggle()}>
								<svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
								</svg>
							</button>
						</div>
					</header>
					<Link to={"/"} className="absolute left-4 top-4 flex md:hidden items-center justify-center h-18 w-32 z-10">
						<Img className="block w-32 h-18 object-contain" fluid={fluid} />
					</Link>
					<header className={"hidden md:flex fixed transition transform duration-500 left-0 -top-full right-0 flex-col bg-white text-gray-900 shadow-lg z-10 " + (offset > 150 ? " translate-y-0 " : " -translate-y-full")}>
						<DesktopNavBar logo={fluid} menu={menu} />
					</header>
					{showBanner && <>
						<BackgroundImage Tag="header" fluid={backgroundImageStack.reverse()} className="flex flex-col text-white" style={{ backgroundSize: "cover", backgroundPosition: "center", minHeight: "60vh" }}>
							<DesktopNavBar logo={fluid} menu={menu} />
							<div className="flex-1 flex flex-col items-center justify-center font-roboto-slab text-center text-white tracking-wider max-w-6xl mx-auto px-8 py-16">
								<h1 className="text-3xl sm:text-6xl font-base">{siteTitle}</h1>
								{subTitle && <h2 className="text-xl md:text-3xl text-orange-400 mt-4">{subTitle}</h2>}
								{Cta}
								{/* {cta && cta.label && <Link to={cta.url || "/"} className="mt-16 px-8 py-3 text-lg rounded-lg bg-orange-400 uppercase tracking-widest">{cta.label}</Link>} */}
							</div>
						</BackgroundImage>
					</>}
					{!showBanner && <header className="relative flex flex-col text-white bg-white text-gray-900 shadow-lg z-10">
						<DesktopNavBar logo={fluid} menu={menu} />
					</header>}
				</>
				)
			}} />
	)
}

// const NavBar = () => {
// 	return (
// 		<StaticQuery
// 			query={headerQuery}
// 			render={({ site = {} }) => {

// 				const { menu = [] } = site

// 				return (
// 					<>
// 						<DesktopNavBar menu={menu} />
// 					</>
// 				)
// 			}} />
// 	)
// }

const DesktopNavBar = ({ menu = [], logo = {} }) => {
	return (
		<nav className="hidden md:flex flex-row justify-between items-center py-4 px-16">
			<Link to={"/"} className="block h-18 w-32"><Img fluid={logo} objectFit="cover" /></Link>
			<div className="uppercase font-semibold text-sm tracking-widest">

				{
					menu.map(({ label = "", url = "/", menuItems = [] }) => {
						return (
							<Link key={url} to={url || "/"} className="relative py-4 px-8 transform duration-200 hover:text-orange-400 group" >
								{label}
								{menuItems.length > 0 &&
									<div class="origin-top-right right-0 pt-4 absolute hidden group-hover:block z-20">
										<div class="py-1 rounded-md bg-white rounded-md shadow-lg">
											{
												menuItems.map(({ label, url = "/" }) => {
													return (
														<Link to={url || "/"} key={url} className="px-8 py-4 block transition duration-200 text-right whitespace-no-wrap text-gray-900 hover:text-orange-400 border-t-2 border-gray-200 first:border-t-0">{label}</Link>
													)
												})
											}
										</div>
									</div>
								}
							</Link>
						)
					})
				}
			</div>
		</nav>
	)
}

const MobileNavBar = ({ menu = [], logo={}, isOpened, onOpen, onClose, onToggle }) => {

	return (
		<div className="md:hidden z-50" >
			<div className={"fixed inset-0 z-30 bg-orange-300 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 " + (isOpened ? "opacity-75 pointer-events-auto " : "opacity-0 pointer-events-none ")} onClick={() => onClose()}></div>
			<div style={{ width: "calc(100% - 3.5rem)" }} className={"fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs bg-white transform ease-in-out duration-300 " + (isOpened ? "translate-x-0 " : "-translate-x-full ")}>
				<div className="absolute top-0 right-0 -mr-14 p-1">
					<button className={"items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600 " + (isOpened ? "flex" : "hidden")} onClick={() => onClose()} >
						<svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div className="h-0 flex-1 overflow-y-auto pb-4">
					<Link to={"/"} className="flex-shrink-0 flex items-center justify-center px-4 h-18 w-32 mb-8 mt-8 text-lg text-gray-900">
						<Img className="block" fluid={logo} objectFit="cover" />
					</Link>
					<nav className="mt-5 px-2 ">
						{
							menu.map(({ url = "/", label = "", menuItems = [] }, index) => {
								return (
									<>
										<Link to={url} key={url + index} onClick={onClose} activeClassName="bg-orange-100" className="flex items-center px-2 py-2 mt-2 text-base leading-6 font-medium rounded-md focus:outline-none transition ease-in-out duration-150">
											{label}
										</Link>
										{
											menuItems.map(({ label, url = "/" }) => {
												return (
													<Link to={url || "/"} key={url} activeClassName="bg-orange-100 rounded-lg" className="pl-8 py-2 block whitespace-no-wrap text-gray-700 hover:text-orange-400">{label}</Link>
												)
											})
										}
									</>
								)
							})
						}
					</nav>
				</div>
			</div >
		</div >
	)
}

const headerQuery = graphql`
  query HeaderQuery {
    site: sanitySiteSettings(_id: {eq: "siteSettings"}) {
		logoImage {
			asset {
				fluid {
					...GatsbySanityImageFluid
				}
			}
		}
		menu {
			label
			url
			menuItems {
			  label
			  url
			}
		  }
    }
  }
`

export default Header
